<template>
    <div>
        <v-row>
            <v-col cols="12">
                <div class="d-flex justify-space-between">
                    <div style="padding: 18px 12px">
                        <div class="text-2xl"><i class="mdi mdi-human-dolly"></i> ຈັດສົ່ງພັດສະດຸ</div>
                    </div>
                    <v-breadcrumbs
                        class="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                        :items="$route.meta.breadcrumb"
                        divider=">"
                        large
                    />

                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <!-- <v-card-title>
                        <v-row>
                            <v-col cols="12">
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-divider class="mx-4"></v-divider> -->
                    <v-card-actions>
                        <v-btn color="green" outlined dark class="mx-1" @click="$router.push({name: 'deliveryadd', params: { title: 'ສ້າງລາຍການຈັດສົ່ງພັດສະດຸ', icon: 'mdi mdi-plus-circle-outline'}})" v-if="adddata">
                            <i class="mdi mdi-plus-circle-outline"></i> 
                            <span class="d-none d-md-block d-lg-block d-xl-block">ສ້າງລາຍການຈັດສົ່ງພັດສະດຸ</span>
                        </v-btn>  
                        <v-btn color="primary" outlined dark class="mx-1" @click="getListPackage()">
                            <i class="mdi mdi-reload"></i> 
                            <span class="d-none d-md-block d-lg-block d-xl-block">ຮີໂຫລດ</span>
                        </v-btn>       
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="ຄົ້ນຫາ"
                            single-line
                            hide-details
                            style="max-width: 250px"
                        />
                    </v-card-actions>
                    <v-data-table
                        :headers="headers"
                        :items="listdata"
                        :search="search"
                        :items-per-page="10"
                        class="elevation-1"
                        item-class="align-top"
                        :loading="$store.state.auth.loading2"
                        loading-text="ກຳລັງໂຫລດ...ກະລຸນາລໍຖ້າ"
                        fixed-header
                        height="530px"
                        :footer-props="{
                            'items-per-page-options': [10, 50, 100, -1],
                            showFirstLastPage: true,
                            'items-per-page-text': 'ຈຳນວນທີ່ສະແດງ:'
                        }"
                        dense
                        show-expand
                        :expanded.sync="expanded"
                        :single-expand="true"
                        item-key="delivery_code"
                    >
                        <template v-slot:item.option="{ item }">
                            <v-btn
                                class="mr-1"
                                color="primary"
                                fab
                                small
                                dark
                                text 
                                @click="printBarcode(item)"
                            >
                                <v-icon>mdi-printer</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:item.cargo_name="{ item }">
                            <div class="whitespace-nowrap">{{ item.cargo_name }}</div>
                        </template>
                        <template v-slot:item.driver_name="{ item }">
                            <div class="whitespace-nowrap">{{ item.driver_name }}</div>
                        </template>
                        <template v-slot:item.date_log="{ item }">
                            {{ $helper.formatFontDate(item.date_log) }}
                        </template>
                        <template v-slot:item.totalamount="{ item }">
                            {{ $helper.formatNumber(item.totalamount, 0) }}
                        </template>
                        <template v-slot:item.totalprice="{ item }">
                            {{ $helper.formatNumber(item.totalprice, 0) }}
                        </template>
                        <template v-slot:item.delivery_status="{ item }">
                            <v-chip
                                class="ma-2"
                                :color="(item.delivery_status == 'Y') ? 'success white--text' : 'red white--text'"
                            >
                                {{ (item.delivery_status == 'Y') ? 'ສຳເລັດແລ້ວ' : 'ຍັງບໍ່ທັນສຳເລັດ' }}
                            </v-chip>
                        </template>
                        <!-- <template v-slot:body="{ items }">
                            <tbody>
                                <tr v-for="rows in items" :key="rows.id">
                                    <td class="text-center align-top whitespace-nowrap">
                                        <v-btn @click="expanded = [items]">Expand</v-btn>
                                        {{ expanded }}
                                    </td>
                                    <td class="text-center align-top whitespace-nowrap">
                                        <v-btn
                                            class="mr-1"
                                            color="primary"
                                            fab
                                            small
                                            dark
                                            text 
                                            @click="printBarcode(rows)"
                                        >
                                            <v-icon>mdi-printer</v-icon>
                                        </v-btn>
                                    </td>
                                    <td class="text-center align-top whitespace-nowrap">{{ rows.rno }}</td>
                                    <td class="text-center align-top whitespace-nowrap">{{ rows.delivery_code }}</td>
                                    <td class="text-center align-top whitespace-nowrap">{{ $helper.formatFontDate(rows.date_log) }}</td>
                                    <td class="text-center align-top whitespace-nowrap">{{ rows.cargo_name }}</td>
                                    <td class="text-center align-top whitespace-nowrap">{{ rows.driver_name }}</td>
                                    <td class="text-center align-top">{{ $helper.formatNumber(rows.totalamount, 0) }}</td>
                                    <td class="text-right align-top">{{ $helper.formatNumber(rows.totalprice, 0) }}</td>
                                </tr>
                            </tbody>
                        </template> -->
                        <template v-slot:expanded-item="{ item, headers  }">
                            <td class="text-center align-top whitespace-nowrap" :colspan="headers.length">
                                <v-alert
                                    color="blue-grey lighten-4"
                                    shaped
                                    dense
                                    class="text-center my-2"
                                >
                                    <strong>ລາຍການຈັດສົ່ງພັດສະດຸ</strong>
                                </v-alert>
                                <v-simple-table dense >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-center white--text primary font12">ລຳດັບ</th>
                                                <th class="text-center white--text primary font12">ເລກທີຕິດຕາມພັດສະດຸ</th>
                                                <th class="text-center white--text primary font12">ຊື່ພັດສະດຸ</th>
                                                <th class="text-center white--text primary font12">ຈຳນວນ</th>
                                                <th class="text-right white--text primary font12">ລາຄາ</th>
                                                <th class="text-left white--text primary font12">ລາຍລະອຽດພັດສະດຸ</th>
                                                <th class="text-left white--text primary font12">ສາຂາຕົ້ນທາງ ຫາ ສາຂາປາຍທາງ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="rows, i in item.sub"
                                                :key="rows.rno"
                                            >
                                                <td class="text-center">{{ i + 1 }}</td>
                                                <td class="text-center">{{ rows.track_code }}</td>
                                                <td class="text-center">{{ rows.track_name }}</td>
                                                <td class="text-center">{{ $helper.formatNumber(rows.track_amount, 0) }}</td>
                                                <td class="text-right">{{ $helper.formatNumber(rows.track_price, 0) }}</td>
                                                <td class="text-left">{{ rows.track_remark }}</td>
                                                <td class="text-left">{{ rows.form_branch_name + " => " + rows.to_branch_name }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </td>
                        </template>
                        
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                search: "",
                headers: [
                    {
                        text: '',
                        align: 'center',
                        sortable: false,
                        value: 'data-table-expand',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                
                    {
                        text: 'ຕົວເລືອກ',
                        align: 'center',
                        sortable: false,
                        value: 'option',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: '#',
                        align: 'center',
                        sortable: false,
                        value: 'rno',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ເລກທີຂົນສົ່ງ',
                        align: 'center',
                        sortable: true,
                        value: 'delivery_code',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ລົງວັນທີ',
                        align: 'center',
                        sortable: true,
                        value: 'date_log',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ລົດຂົນສົ່ງ',
                        align: 'center',
                        sortable: true,
                        value: 'cargo_name',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ພະນັກງານຂັບລົດ',
                        align: 'center',
                        sortable: true,
                        value: 'driver_name',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ລວມຈຳນວນທັງໝົດ',
                        align: 'center',
                        sortable: true,
                        value: 'totalamount',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ລວມເປັນເງີນທັງໝົດ',
                        align: 'end',
                        sortable: false,
                        value: 'totalprice',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ສະຖານະ',
                        align: 'center',
                        sortable: false,
                        value: 'delivery_status',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    
                ],
                listdata: [],
                adddata: false,
                expanded: [],
            }
        },
        async created() {
            await this.getListPackage();
        },
        methods: {
            async getListPackage(){
                const formdata = await {
                    action: "listdelivery",
                    route: this.$route.name,
                    id: '',
                    start: '',
                    end: '',
                }
                this.listdata = await [];
                await this.$store.commit("auth/setLoading2", true);
                const result = await this.$store.dispatch("manage/getManagePackage", formdata);
                await this.$store.commit("auth/setLoading2", false);
                if(result.success){
                    this.listdata = await result.data;
                }
                this.adddata = await result.r_add;
            },
            async printBarcode(data){
                console.log(data)
                this.$swal.fire({
                    title: 'Oops',
                    text: 'ກຳລັງພັດທະນາ',
                    icon: 'warning',
                });
            }
        },
    }
</script>


<style>
    .align-top{
        vertical-align: top;
    }
    .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .font12{
        font-size: 10pt !important;
    }
</style>